import {$, $$} from '../helpers'
import PasswordValidator from 'password-validator'

const passField = $('#password');
const tooltipElement = $('.password-validation-rules');

// Create a schema
let schema = new PasswordValidator();
schema
    .is().min(8, 'Minimum 8 character length')
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().symbols(1)
    .has().digits(1, 'Must have at least 1 digit')
    .has().not().spaces()

$$('.pass-information-help').forEach(el => {
    el.addEventListener('mouseover', () => {
        tooltipElement.className = 'password-validation-rules bottom show'
    })
    el.addEventListener('mouseleave', () => {
        tooltipElement.className = 'password-validation-rules'
    })
});

if (passField) {
    passField.addEventListener('focusin', () => {
        tooltipElement.className = 'password-validation-rules top show'
    });
    passField.addEventListener('focusout', () => {
        tooltipElement.className = 'password-validation-rules'
    });
}

$$('#registerForm').forEach(form => {
    const showPassCheckbox = $('#show_pass', form)
    // const passField = $('#password', form);
    const validationRuleListElement = $('ul.validation-rules');
    const validationRuleElement = $$('ul.validation-rules .rule');
    const registerBtn = $('#register-submit');

    showPassCheckbox.addEventListener('change', () => {
        if (showPassCheckbox.checked) {
            passField.setAttribute('type', 'text');
        } else {
            passField.setAttribute('type', 'password');
        }
    });

    registerBtn.addEventListener('click', (e) => {
        if (validationRuleListElement.classList.contains('invalid')) {
            e.preventDefault();
            passField.focus();
        }
    });

    passField.addEventListener('keyup', () => {
        try {
            let isMin = false, isUppercase = false, isLowerCase = false, isSpecial = false, isDigit = false;
            let result = schema.validate(passField.value, {list: true})
            let list = result.toString().split(',');
            for (let i = 0; i < list.length; i++) {
                switch (true) {
                    case (list[i] === 'min'):
                        isMin = true;
                        break;
                    case (list[i] === 'uppercase'):
                        isUppercase = true;
                        break;
                    case (list[i] === 'lowercase'):
                        isLowerCase = true;
                        break;
                    case (list[i] === 'symbols'):
                        isSpecial = true;
                        break;
                    case (list[i] === 'digits'):
                        isDigit = true;
                        break;
                }
            }
            validationRuleElement.forEach(element => {
                if (!isMin && element.classList.contains('min')) {
                    element.className = "rule min success"
                } else if (isMin && element.classList.contains('min')) {
                    element.className = "rule min invalid"
                }
                if (!isUppercase && element.classList.contains('uppercase')) {
                    element.className = "rule uppercase success"
                } else if (isUppercase && element.classList.contains('uppercase')) {
                    element.className = "rule uppercase invalid"
                }
                if (!isLowerCase && element.classList.contains('lowercase')) {
                    element.className = "rule lowercase success"
                } else if (isLowerCase && element.classList.contains('lowercase')) {
                    element.className = "rule lowercase invalid"
                }
                if (!isSpecial && element.classList.contains('special')) {
                    element.className = "rule special success"
                } else if (isSpecial && element.classList.contains('special')) {
                    element.className = "rule special invalid"
                }
                if (!isDigit && element.classList.contains('digit')) {
                    element.className = "rule digit success"
                } else if (isDigit && element.classList.contains('digit')) {
                    element.className = "rule digit invalid"
                }
            })
            if (!isMin && !isUppercase && !isLowerCase && !isSpecial && !isDigit) {
                validationRuleListElement.className = 'validation-rules valid';
            } else {
                validationRuleListElement.className = 'validation-rules invalid';
            }
        } catch (error) {
            error.message;
        }
    });
});
