import { $, $$ } from './../helpers'

// Nav
const menu = $('.site-header__menu')

// Toggle
$('.site-header__menu-toggle').addEventListener('click', () => {
    menu.classList.toggle('site-header__menu--active');
    const body = document.getElementsByTagName('body');
    if(body.length > 0) {
        body[0].classList.toggle('overflow-hidden');
        body[0].classList.toggle('mobile-menu-open');
    }

    setMenuHeight();
});

function setMenuHeight() {
    const menu = document.querySelector('nav.site-header__menu');
    const header = document.getElementById('site-header-element');
    if(menu && header) {
        const windowHeight = window.innerHeight;
        if(window.outerWidth < 1080) {
            menu.style.height = `${windowHeight - header.offsetHeight}px`;
        }
        else {
            menu.style.height = `auto`;
        }
    }
}

/** Runtime adjustment for tagline **/
document.addEventListener('DOMContentLoaded', setContentTopOffset);
window.addEventListener('resize', function() {
    setContentTopOffset();
    setMenuHeight();
});

function setContentTopOffset() {
    const header = document.getElementById('site-header-element');
    const main = document.getElementById('skip-content');
    if(header && main) {
        main.style.marginTop = `${header.offsetHeight}px`;
    }
}
/** Runtime adjustment for tagline **/