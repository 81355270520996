
const searchEdubase = search => {
    return fetch(`/api/edubase/v1/search?query=${search}`)
        .then(response => response.json());
}

export default function (el) {
    const resultContainer = document.getElementById('school-lookup-control-autocomplete');
    const urnField = document.getElementById('school-url-field');
    
    const delay = (callback, ms) => {
        var timer = 0;
        return function() {
          var context = this, args = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
            callback.apply(context, args);
          }, ms || 0);
        };
      }
  
    const updateLoadingState = loading => {
        const formGroup = urnField.closest('.form__group');
        if (loading) {
            formGroup.classList.add('loading');
        } else {
            formGroup.classList.remove('loading');
        }
    };

    const updateSchool = result => {
        let schoolRows = ``;
        if (!result || !result.schools) {
            if (el.required) {
                schoolRows = '<div class="error">Establishment not found. Please enter a valid URN.</div>';
                el.parentNode.classList.add('has-error');
            } else {
                resultContainer.innerHTML = '';
            }
        } else {
            el.parentNode.classList.remove('has-error');

            for (let x in result.schools) {
                let school = result.schools[x];
                schoolRows = schoolRows + `<div class="autocomplete__results-item" data-schoolid="${school.id}">${school.name}, ${school.postcode} (${school.id})</div>`;
            }
        }
                    /*const { establishmentname, street, town, postcode, telephonenum, schoolwebsite } = result*/

            /*
            <div class="autocomplete__results" v-if="results.length > 0">
                        <div class="autocomplete__results-item" v-for="result in results" @click="handleSelect(result)">
                            {{ result.name }}, {{ result.postcode }} <span class="fg-brand-mid">({{ result.id }})</span>
                        </div>
                    </div>
            */

            /*resultContainer.innerHTML = `
                <dl>
                    <dt>Name:</dt>
                    <dd>${establishmentname}</dd>
                    <dt>Address:</dt>
                    <dd>${street}, ${town}, ${postcode}</dd>
                    <dt>Phone:</dt>
                    <dd>${telephonenum}</dd>
                    <dt>Website:</dt>
                    <dd>${schoolwebsite}</dd>
                </dl>
            `*/


        resultContainer.style.display = 'block';

        resultContainer.innerHTML = schoolRows;

        updateLoadingState(false); // Remove loading class after data is received

        const schools = resultContainer.children;
        for (let i = 0; i < schools.length; i++) {
            schools[i].addEventListener('click', (e) => {
                let targetEl = e.target;

                for (let i = 0; i < schools.length; i++) {
                    schools[i].classList.remove('selected');
                }

                targetEl.classList.add('selected');
                let selectedSchoolID = targetEl.getAttribute('data-schoolid');
                urnField.value = selectedSchoolID;
                el.value = targetEl.innerHTML;

                resultContainer.style.display = 'none';
            }, false);
        }
    }

    if (urnField && urnField.value) {
        updateLoadingState(true); // Add loading class when initiating the API call
        searchEdubase(urnField.value || false).then(json => updateSchool(json))
    }

    let timeout = null
    el.addEventListener('keyup', (e) => {
        updateLoadingState(true); // Add loading class when initiating the API call
        let text = el.value;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            if (text) {
                searchEdubase(el.value).then(json => updateSchool(json));
            } else {
                updateSchool(null);
            }
        }, 1000);
    });
}
