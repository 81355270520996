import { $$ } from './../helpers'

const checkWords = el => {
    const wordCount = el.value.trim().split(/\s+/).length;

    // Minwords
    if (el.hasAttribute('minwords') && wordCount < parseInt(el.getAttribute('minwords'))) {
        return false;
    }

    // Maxwords
    if (el.hasAttribute('maxwords') && wordCount > parseInt(el.getAttribute('maxwords'))) {
        return false;
    }

    return true;
}

$$('[minwords], [maxwords]').forEach(control => {
    // Create a word count display element
    const wordCountDisplay = document.createElement('span');
    wordCountDisplay.classList.add('word-count-display');
    control.parentNode.insertBefore(wordCountDisplay, control.nextSibling);

    const updateWordCountDisplay = () => {
        const wordCount = control.value.trim().split(/\s+/).filter(word => word !== '').length;
        wordCountDisplay.textContent = `Word count: ${wordCount}`;
    };

    const validate = () => {
        const isValid = checkWords(control);
        if (!isValid) {
            control.setCustomValidity('Word count exceeds the limit.');
            control.classList.add('error'); // Add a CSS class to highlight the textarea in red
        } else {
            control.setCustomValidity('');
            control.classList.remove('error'); // Remove the CSS class if the input is valid
        }
        updateWordCountDisplay();
    };

    control.addEventListener('input', () => validate(), false);
    control.addEventListener('blur', () => validate(), false);

    validate();
});
