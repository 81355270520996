import { $, $$, createElement } from './../helpers'
import passwordStrength from 'check-password-strength'

$$('.js-password-strength').forEach(el => {
    // Create and append indicator
    const indicator = createElement('div', { className: 'password-strength' })
    el.after(indicator)

    el.addEventListener('keyup', () => {
        try {
            const result = passwordStrength(el.value)
            indicator.className = `password-strength  password-strength--score-${result.id}`
        } catch {
            indicator.className = 'password-strength'
        }
    })

})
