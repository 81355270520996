import 'cookieconsent'

cookieconsent.initialise({
    "palette": {
        "popup": {
            "background": "#f5f5f5",
            "text": "#444"
        },
        "button": {
            "background": "#ff006d",
            "text": "#fff"
        }
    },
    "theme": "classic",
    "content": {
        "dismiss": "Dismiss",
        "link": "Learn more",
        "href": "https://governorsforschools.org.uk/privacy-policy"
    }
})
