import './polyfills'

import './modules/menu'
import './modules/expanded'
import './modules/responsive-media'
import './modules/carousel'
import './modules/modal'
import './modules/autocapitalize'
import './modules/file-control'
import './modules/min-max-words'
import './modules/password-strength'
import './modules/cookie-consent'
/*import './modules/sticky-header'*/

import './pages/login'
import './pages/register'
import './pages/new-register'
