import { $, $$, createElement } from './../helpers'

$$('#loginform').forEach(form => {
    // Focus on username field
    $('#user_login').focus()

    // Fix checkbox HTML
    const rememberMe = $('.login-remember', form)
    const rememberMeLabel = $('label', rememberMe)
    const rememberMeCheckbox = $('#rememberme')

    rememberMeLabel.setAttribute('for', 'rememberme')

    const checkboxWrapper = createElement('div', { className: 'custom-choice-control' })
    checkboxWrapper.append(rememberMeCheckbox)
    checkboxWrapper.append(rememberMeLabel)

    rememberMe.append(checkboxWrapper)
})
