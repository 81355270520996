import { $, $$ } from './../helpers'
import Glide from '@glidejs/glide'

$$('.glide.partners').forEach(el => {
    const carousel = new Glide(el, {
        type: 'carousel',
        gap: 10,
        perView: 5,
        breakpoints: {
            768: {
                perView: 3,
            },
            480: {
                perView: 2
            },
            380: {
                perView: 1
            }
        }
    })

    carousel.mount()
});

$$('.glide.home-page-carousel').forEach(el => {
    const carousel = new Glide(el, {
        type: 'carousel',
        gap: 2,
        perView: 1,
        autoplay: 5000,
        breakpoints: {
            768: {
                perView: 1,
            },
            480: {
                perView: 1
            },
            380: {
                perView: 1
            }
        }
    })

    carousel.mount()
});

$$('.glide.home-page-testimonial-carousel').forEach(el => {
    const carousel = new Glide(el, {
        type: 'carousel',
        gap: 2,
        perView: 1,
        autoplay: 5000,
        breakpoints: {
            768: {
                perView: 1,
            },
            480: {
                perView: 1
            },
            380: {
                perView: 1
            }
        }
    })

    carousel.mount()
});

$$('.glide.home-page-statistics-carousel').forEach(el => {
    const carousel = new Glide(el, {
        type: 'carousel',
        gap: 2,
        perView: 1,
        autoplay: 5000,
        breakpoints: {
            768: {
                perView: 1,
            },
            480: {
                perView: 1
            },
            380: {
                perView: 1
            }
        }
    })

    carousel.mount()
});

