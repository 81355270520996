import { $$ } from './../helpers'

const capFirst = str => `${str.charAt(0).toUpperCase()}${str.substr(1)}`

$$('input[autocapitalize]').forEach(control => {
    const method = control.getAttribute('autocapitalize')

    control.addEventListener('input', e => {
        switch (method) {
            case 'characters':
                control.value = control.value.toUpperCase()
                break;
            case 'words':
                control.value = control.value.replace(/\w+/g, capFirst)
                break;
            case 'sentences':
                control.value = control.value.split('. ').map(capFirst).join('. ')
                break;
        }
    })

})
